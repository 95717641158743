<template>
  <g :fill="color" fill-rule="nonzero">
    <path d="M9 1L3.667 5 1 2.333" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
